import { Box, Typography } from "@mui/material";
import { useLanguageContext } from "../../context/LanguageContext";
import DefaultPalette from "../../theme/palette";

const convertMinutesToHoursAndMinutes = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (hours === 0 && minutes === 0) {
    return '0';
  }

  else if (hours === 0) {
    return `${minutes}min`;
  }

  else if (minutes === 0) {
    return `${hours}h`;
  }

  return `${hours}h ${minutes}min`;};

const WeekStatsItem = ({ week, weeklyLoad, strain, monotony, all_trainings_minutes }: any) => {
  const { i18n } = useLanguageContext();
  const palette = DefaultPalette("dark", "custom");
  const weekData = [
    {
      label: i18n.graphs.week,
      value: week,
    },
    {
      label: i18n.graphs.wtl,
      value: weeklyLoad,
    },
    {
      label: i18n.graphs.strain,
      value: strain,
    },
    {
      label: i18n.graphs.monotony,
      value: monotony,
    },
    {
      label: i18n.graphs.all_trainings_minutes,
      value: convertMinutesToHoursAndMinutes(all_trainings_minutes),
    }
  ];

  const styles = {
    itemBox: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 2,
    },
    textStyle: {
      flex: 1,
      color: palette.common.white,
      fontWeight: 500,
      textAlign: "left",
      marginRight: "2px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    textBox: {
      padding: "4px 8px",
      borderRadius: "6px",
      minWidth: 100,
      textAlign: "center",
      backgroundColor: palette.custom.blackBackground,
      border: `1px solid ${palette.common.white}`,
      color: palette.common.white,
    },
  };
  return (
    <Box>
      {weekData.map((item: any, index: number) => (
        <Box key={index} sx={styles.itemBox}>
          <Typography sx={styles.textStyle}>{item.label}</Typography>
          <Typography sx={styles.textBox}>{item.value}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default WeekStatsItem;

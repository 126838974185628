import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DefaultPalette from "../../theme/palette";
import arrowRight from "../../resources/svg/arrowRightSvg.svg";


const AthleteListItem = (props: any) => {
  const { title, subtitle, subtitle2, isSelected, isAthlete, onArrowClick, onCardClick } = props;
  const palette = DefaultPalette("dark", "custom");

  return (
    <Card
      sx={{
        height: "80px",
        borderRadius: "4px",
        marginRight: "10px",
        ...(isAthlete ? { width: "105%" } : {}),
        backgroundColor: isSelected ? "#323232" : !isAthlete ? palette.custom.background : "transparent",
        border: !isAthlete ? `1px solid ${palette.custom.gray}` : "none",
        cursor: "pointer",
      }}
      onClick={onCardClick}
    >
      <CardContent sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
        <Grid container alignItems="center" justifyContent={!isAthlete ? "space-between" : "normal"}>
          <Grid item xs={3} sx={{display: "flex", justifyContent: "center"}}>
            <IconButton sx={{ marginTop: "-1px" }}>
              <Avatar sx={{ minWidth: "24px", minHeight: "24px", maxWidth: "58px", maxHeight: "58px" }} />
            </IconButton>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="column" justifyContent="space-around">
              <Typography
                color={palette.custom.white}
                fontWeight={"700"}
                fontSize={"18px"}
              >
                {title}
              </Typography>
              <Typography color={palette.custom.gray} fontSize={"12px"}>
                {subtitle}
              </Typography>
              <Typography color={palette.custom.orange} fontSize={"12px"}>
                {subtitle2}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={1}>
            <IconButton onClick={onArrowClick}>
              <img src={arrowRight} alt="Arrow Right Icon" />
            </IconButton>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AthleteListItem;

import React, { useContext, useEffect, useRef, useState } from "react";
import { getAnnualPlans, selectPlan } from "../app/reducers/plansSlice";
import { MainContext } from "../app/context/MainContext";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Box, Grid, Typography } from "@mui/material";
import AthleteList from "../app/components/athlete/AthleteList";
import {
  athleteNewestAnnualPlans,
  mapAnnualPlansToItems,
} from "../app/resources/simpleFunction";
import { Role } from "../app/utils/enums";
import NewRow from "../app/components/NewRow";
import EmptyComponent from "../app/components/EmptyComponent";
import { ListAlt } from "@mui/icons-material";
import { LanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";
import WeekStatsList from "../app/components/dashboard/WeekStatsList";
import PieGraph from "../app/components/dashboard/PieGraph";
import CombineGraphAnnual from "../app/components/dashboard/CombineGraphAnnual";
import LegendPie from '../app/components/dashboard/LegendPie';

const DashboardPage = (): React.ReactElement => {
  const plans = useAppSelector(selectPlan);
  const { currentProfile } = useContext(MainContext);
  const dispatch = useAppDispatch();
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  const [weeks, setWeeks] = useState<any>([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const parentRef = useRef<HTMLDivElement>(null);
  const isAthlete = currentProfile?.role === Role.Athlete;

  // Update parent dimensions
  useEffect(() => {
    const updateDimensions = () => {
      if (parentRef.current) {
        setDimensions({
          width: parentRef.current.offsetWidth,
          height: parentRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    dispatch(getAnnualPlans());
  }, [dispatch]);

  const COLORS = [
    { name: "PHYSICAL", color: palette.custom.graph.blue },
    { name: "RECOVERY", color: palette.custom.graph.orange },
    { name: "TACTICAL", color: palette.custom.graph.yellow },
    { name: "TECHNICAL", color: palette.custom.graph.grey },
  ];

  const styles = {
    container: {
      minHeight: "82vh",
      margin: 2,
    },
    borderBox: {
      borderRadius: "33px",
      backgroundColor: palette.custom.background,
      padding: "15px",
    },
    darkBorderBox: {
      borderRadius: "33px",
      backgroundColor: palette.custom.darkBackground,
      padding: "15px",
    },
    athleteListContainer: {
      overflowY: "auto",
      height: "89vh",
      padding: 2,
    },
    weeklyStatsTitle: {
      color: palette.common.white,
      marginBottom: 2,
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    plannedReportedStats: {
      textAlign: "center",
      color: palette.common.white,
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    centerGraph: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <React.Fragment>
      <NewRow height={"90px"} />
      <Box ref={parentRef} sx={styles.container}>
        {plans.values && plans.values.length === 0 && (
          <EmptyComponent
            paddingTop="5rem"
            fontSize={"h4"}
            text={i18n.appData.noAnnualPlan}
            icon={
              <ListAlt sx={{ fontSize: "3rem", color: palette.custom.gray }} />
            }
          />
        )}

        {plans.values && plans.values.length > 0 && (
          <Grid container spacing={2} ml={0}>
            {!isAthlete ? (
              <>
                {Object.keys(plans.valueStats)?.length > 0 ? (
                  <Grid item container spacing={1} xs={12} md={9} mt={2}>
                    <Grid container spacing={2} sx={styles.darkBorderBox}>
                      <Grid item xs={12}>
                        <Typography sx={styles.weeklyStatsTitle}>
                          {i18n.graphs.weekly_statistic}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={
                          Object.keys(plans.valueStats.weekly_statistic)
                            ?.length > 0
                            ? 4
                            : 12
                        }
                      >
                        <WeekStatsList
                          weekStats={plans.valueStats.weekly_statistic ?? {}}
                          weeks={weeks}
                        />
                      </Grid>
                      {Object.keys(plans.valueStats.weekly_statistic)?.length >
                        0 && (
                        <Grid item xs={12} md={8} sx={styles.centerGraph}>
                          <CombineGraphAnnual
                            weekStats={plans.valueStats.weekly_statistic ?? {}}
                            weeks={weeks}
                            width={dimensions.width * 0.4}
                            height={dimensions.height * 0.4}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ ...styles.borderBox, marginTop: "20px" }}
                    >
                      <Grid item xs={12}>
                     <Typography sx={styles.weeklyStatsTitle}>
                          {i18n.graphs.training_focus}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{p: 0}}>
                        <Typography sx={styles.plannedReportedStats}>
                          {i18n.graphs.planned_statistic}
                        </Typography>

                        <PieGraph
                          data={plans.valueStats?.planned_statistic ?? {}}
                          COLORS={COLORS}
                          width={dimensions.width * 0.4}
                          height={dimensions.height * 0.4}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{p: 0}}>
                        <Typography sx={styles.plannedReportedStats}>
                          {i18n.graphs.reported_statistic}
                        </Typography>
                        <PieGraph
                          data={plans.valueStats?.report_statistic ?? {}}
                          COLORS={COLORS}
                          width={dimensions.width * 0.4}
                          height={dimensions.height * 0.4}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LegendPie COLORS={COLORS} />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={9}>
                    <EmptyComponent
                      paddingTop="5rem"
                      text={i18n.graphs.choose_plan}
                      showText={true}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  <Box sx={styles.athleteListContainer}>
                    <AthleteList
                      data={mapAnnualPlansToItems(
                        athleteNewestAnnualPlans(plans.values)
                      )}
                      setWeeks={setWeeks}
                      isAthlete={false}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                {/* Athlete List */}
                <Grid item xs={12}>
                  <Box>
                    <AthleteList
                      data={mapAnnualPlansToItems(plans.values)}
                      setWeeks={setWeeks}
                      isAthlete={true}
                    />
                  </Box>
                </Grid>

                {Object.keys(plans.valueStats)?.length > 0 ? (
                  <>
                    <Grid
                      container
                      spacing={2}
                      sx={{ ...styles.darkBorderBox, marginTop: "20px", height: "40vh" }}
                    >
                      <Grid item xs={12}>
                        <Typography sx={styles.weeklyStatsTitle}>
                          {i18n.graphs.weekly_statistic}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={
                          Object.keys(plans.valueStats.weekly_statistic)
                            ?.length > 0
                            ? 4
                            : 12
                        }
                      >
                        <WeekStatsList
                          weekStats={plans.valueStats.weekly_statistic ?? {}}
                          weeks={weeks}
                        />
                      </Grid>
                      {Object.keys(plans.valueStats.weekly_statistic)?.length >
                        0 && (
                        <Grid item xs={12} md={8} sx={styles.centerGraph}>
                          <CombineGraphAnnual
                            weekStats={plans.valueStats.weekly_statistic ?? {}}
                            weeks={weeks}
                            width={dimensions.width * 0.6}
                            height={dimensions.height * 0.4}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      sx={{ ...styles.borderBox, marginTop: "20px", minHeight: "40vh" }}
                    >
                      <Grid item xs={12}>
                        <Typography sx={styles.weeklyStatsTitle}>
                          {i18n.graphs.training_focus}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{p: 0}}>
                        <Typography sx={styles.plannedReportedStats}>
                          {i18n.graphs.planned_statistic}
                        </Typography>
                        <PieGraph
                          data={plans.valueStats?.planned_statistic ?? {}}
                          COLORS={COLORS}
                          width={dimensions.width * 0.5}
                          height={dimensions.height * 0.4}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{p: 0}}>
                        <Typography sx={styles.plannedReportedStats}>
                          {i18n.graphs.reported_statistic}
                        </Typography>
                        <PieGraph
                          data={plans.valueStats?.report_statistic ?? {}}
                          COLORS={COLORS}
                          width={dimensions.width * 0.5}
                          height={dimensions.height * 0.4}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LegendPie COLORS={COLORS} />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <EmptyComponent
                      paddingTop="5rem"
                      text={i18n.graphs.choose_plan}
                      showText={true}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      </Box>
    </React.Fragment>
  );
};

export default DashboardPage;

import { Box } from '@mui/material';
import { Legend, LegendProps, LegendType } from "recharts";

interface Props {
  COLORS: { name: string; color: string }[];
}

const renderLegend = (props: LegendProps): JSX.Element => {
  const { payload } = props;

  if (!payload) {
    return <></>;
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      {payload.map((entry, index) => (
        <Box
          key={`item-${index}`}
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
            fontSize: 14,
            color: "white",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: 14,
              height: 14,
              backgroundColor: entry.color,
              marginRight: 8,
            }}
          ></span>
          {entry.value}
        </Box>
      ))}
    </Box>
  );
};

const LegendPie = ({ COLORS }: Props): JSX.Element => {
  const legendPayload = COLORS.map((item) => ({
    value: item.name,
    color: item.color,
    type: "square" as LegendType,
  }));

  return (
    <Legend
      verticalAlign="top"
      height={36}
      wrapperStyle={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      payload={legendPayload}
      content={(props) => renderLegend(props as LegendProps)}
    />
  );
};

export default LegendPie;
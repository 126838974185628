import moment from "moment";
import { plansService } from "../services/plansService";
import { WeekStatus } from "../utils/enums";

export const getDateMonthFormat = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  return `${day}/${month}`;
};

export const getDayOfWeekFormat = (dateString: string): string => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const date = new Date(dateString);
  const dayOfWeekIndex = date.getDay();
  return daysOfWeek[dayOfWeekIndex];
};

export const convertToDotDate = (date: string) => {
  const dateParts = date.split("/");
  const dateObject: any = new Date(
    `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
  );

  return dateObject;
};

export const getFirstAndLastDateGetTime = (array: any) => {
  const first = array[0]?.weeks[0]?.week_start_date?.split('T')[0]
  const last = array[array.length-1]?.weeks[array[array.length-1]?.weeks.length-1]?.week_end_date.split('T')[0]

  return {
    first: new Date(first).getTime(),
    last: new Date(last).getTime(),
  }
}

export const getFormattedDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getFormattedChartDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const findWeekTime = (startWeekDate: any, endWeekDate: any): any => {
  const start = startWeekDate.includes("/")
    ? convertToDotDate(startWeekDate)
    : startWeekDate;
  const end = endWeekDate.includes("/")
    ? convertToDotDate(endWeekDate)
    : endWeekDate;
  const startDateTime = moment.utc(new Date(start), "DD/MM/YYYY");
  const endDateTime = moment.utc(new Date(end), "DD/MM/YYYY");

  const todayDateTime = moment.utc(new Date().getTime());
  if (
    todayDateTime.isSameOrAfter(startDateTime) &&
    todayDateTime.isSameOrBefore(endDateTime)
  ) {
    return WeekStatus.Current;
  } else if (todayDateTime.isSameOrAfter(endDateTime)) {
    return WeekStatus.Past;
  } else {
    return WeekStatus.Future;
  }
};

export const getCurrentMonth = (): string => {
  const currentDate = new Date();
  const monthIndex = currentDate.getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthIndex];
};

export function maxObjectsLength(data: any[]) {
  let maxLength = 0;
  try {
    for (const item of data) {
      const objects = item?.objects || [];
      maxLength = Math.max(maxLength, objects.length);
    }
  } catch (error) {
    console.log("Error:", error);
    return 0;
  }

  return maxLength;
}

export function getStartAndEndDateOfLastWeek() {
  const now = new Date();
  const sabirac = now.getDay() === 0 ? -6 : 1;
  const firstDayOfWeek = now.getDate() - now.getDay() + sabirac; // Adjust to the first day of the current week (Sunday = 0, Monday = 1, etc.)

  // Calculate the first day of the previous week by subtracting 7 days from the first day of the current week
  const firstDayOfLastWeek = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), firstDayOfWeek - 7, 0, 0, 0, 0)
  );

  // Calculate the last day of the previous week by subtracting 1 day from the first day of the current week
  const lastDayOfLastWeek = new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      firstDayOfWeek - 1,
      23,
      59,
      59,
      0
    )
  );

  return { startDate: firstDayOfLastWeek, endDate: lastDayOfLastWeek };
}

export const valuesForCalendar = (data: any) => {
  const currentMonth = new Date().getMonth();
  return (
    data &&
    data.flatMap((v: any) => {
      return (
        v?.competitions &&
        v.competitions
          .filter((d: any) => {
            const competitionDate = new Date(d?.date_of_competition);
            return competitionDate.getMonth() === currentMonth;
          })
          .map((competition: any) => ({
            athleteName: v.athlete.name,
            competition,
          }))
      );
    })
  );
};

export function hasDateOfCompetition(obj: any) {
  return obj.hasOwnProperty("date_of_competition");
}

export const convertDateToUTC = (
  dateString: any,
  isEndTime = false
): string => {
  const [datePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("/");
  const time = isEndTime ? "23:59:59" : "00:00:00";
  const date = `${year as string}-${month as string}-${
    (Number(day) < 10 ? "0" + Number(day) : Number(day)) as string
  }T${time}`;

  return date;
};

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const convertDateToCalendarRender = (
  dateString: any,
  isEndTime = false
): string => {
  const [datePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  const time = isEndTime ? "23:59:59" : "00:00:00";
  const date = `${year as string}-${month as string}-${
    (Number(day) < 10 ? "0" + Number(day) : Number(day)) as string
  }T${time}`;

  return date;
};

export const convertDateForFilterWeek = (
  dateString: any,
  isEndTime = false
): string => {
  const [datePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("/");
  const time = isEndTime ? "23:59:59" : "00:00:00";
  const date = `${year as string}-${month as string}-${
    (Number(day) < 10 ? "0" + Number(day) : Number(day)) as string
  }T${time}`;

  return date;
};

export const convertDateForSessionValues = (
  dateString: any,
  isEndTime = false
): string => {
  const [datePart] = dateString.split(" ");
  const [month, day, year] = datePart.split("/");
  const time = isEndTime ? "23:59:59" : "00:00:00";
  const date = `${year as string}-${
    (Number(month) < 10 ? "0" + Number(month) : Number(month)) as string
  }-${(Number(day) < 10 ? "0" + Number(day) : Number(day)) as string}T${time}`;

  return date;
};

export const getFormatedWeekArrayStaro = (
  weekPlanData: any,
  startDayTime: any,
  endDayTime: any
): any => {
  const modifiedArray = weekPlanData?.trainings?.reduce(
    (acc: any, obj: any) => {
      const existingDay = acc.find(
        (item: any) => item.day === obj.day_time.split("T")[0]
      );

      if (existingDay) {
        existingDay.objects.push(obj);
      } else {
        acc.push({ day: obj.day_time.split("T")[0], objects: [obj] });
      }

      return acc;
    },
    []
  );

  const daysInWeek: any = [];
  modifiedArray.forEach((item: any) => {
    daysInWeek.push(item.day);
    let competitionOnDay = weekPlanData?.competitions?.find(
      (comp: any) => comp.date_of_competition === item.day
    );
    if (competitionOnDay) {
      item.objects.push(competitionOnDay);
    }
    item.objects.sort((a: any, b: any) => {
      let dayTimeA = a.day_time ? a.day_time : a.date_of_competition;
      let dayTimeB = b.day_time ? b.day_time : b.date_of_competition;
      return dayTimeA.localeCompare(dayTimeB);
    });
  });

  const currentDate = new Date(convertDateToUTC(startDayTime));
  const finalDate = new Date(convertDateToUTC(endDayTime, true));

  while (currentDate <= finalDate) {
    if (!daysInWeek.includes(currentDate.toISOString().split("T")[0])) {
      modifiedArray.push({
        day: currentDate.toISOString().split("T")[0],
        objects: [],
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  modifiedArray.sort((a: any, b: any) => a.day.localeCompare(b.day));

  return modifiedArray;
};

export const getFormatedWeekArray = (
  weekPlanData: any,
  startDayTime: any,
  endDayTime: any
): any => {
  const modifiedArray = weekPlanData?.trainings?.reduce(
    (acc: any, obj: any) => {
      const existingDay = acc.find(
        (item: any) => item?.day === obj?.day_time.split("T")[0]
      );

      if (existingDay) {
        existingDay?.objects.push(obj);
      } else {
        acc.push({ day: obj?.day_time.split("T")[0], objects: [obj] });
      }

      return acc;
    },
    []
  );

  const currentDate = new Date(convertDateToUTC(startDayTime));
  const finalDate = new Date(convertDateToUTC(endDayTime, true));

  while (currentDate <= finalDate) {
    // const day = currentDate.toISOString().split("T")[0];
    const day = moment(currentDate).format("YYYY-MM-DD");
    const existingDay = modifiedArray.find((item: any) => item.day === day);

    if (!existingDay) {
      modifiedArray.push({
        day,
        objects: [],
      });
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  modifiedArray.forEach((item: any) => {
    let competitionOnDay = weekPlanData?.competitions?.find(
      (comp: any) => comp?.date_of_competition === item?.day
    );
    if (competitionOnDay) {
      item.objects.push(competitionOnDay);
    }
    item.objects.sort((a: any, b: any) => {
      let dayTimeA = a.day_time ? a.day_time : a.date_of_competition;
      let dayTimeB = b.day_time ? b.day_time : b.date_of_competition;
      return dayTimeA.localeCompare(dayTimeB);
    });
  });

  modifiedArray.sort((a: any, b: any) => a.day.localeCompare(b.day));

  return modifiedArray;
};

//Generates a skeleton for table
// export const getSeasonMonthsAndWeeks = (startDate: any, endDate: any) => {
//   const months = [];
//   let currentDate = new Date(startDate);
//   let mc = 0;

//   while (currentDate <= new Date(endDate)) {
//     const monthName = currentDate.toLocaleString("en-US", { month: "long" });

//     const weeks = [];

//     const nextMonth: any = new Date(
//       currentDate.getFullYear(),
//       currentDate.getMonth() + 1,
//       1
//     );
//     const lastDateOfMonth = new Date(nextMonth - 1);

//     const startOfWeek = new Date(currentDate);
//     let endOfWeek = new Date(currentDate);

//     while (endOfWeek <= lastDateOfMonth) {
//       if (startOfWeek.getDay() === 1) {
//         const weekStartDate = new Date(startOfWeek);
//         const weekEndDate = new Date(endOfWeek);
//         weekEndDate.setDate(weekEndDate.getDate() + 6);
//         weekEndDate.setHours(23, 59, 0, 0);

//         weekStartDate.setDate(weekStartDate.getDate() + 1);
//         weekStartDate.setHours(0, 0, 0, 0);

//         weeks.push({
//           week_start_date: convertDateToCalendarRender(
//             weekStartDate.toISOString()
//           ),
//           week_end_date: convertDateToCalendarRender(
//             weekEndDate.toISOString(),
//             true
//           ),
//           number: mc + 1,
//         });

//         mc++;
//       }

//       startOfWeek.setDate(startOfWeek.getDate() + 1);
//       endOfWeek = new Date(startOfWeek);
//     }

//     months.push({
//       month: monthName,
//       weeks: [...weeks],
//     });

//     currentDate = nextMonth;
//   }

//   return months;
// };

export const getAnnualStatsByWeek = (data: any, weeksArray: any[]) => {
  const result: any = [];
  const today = new Date();

  weeksArray.forEach((monthObj) => {
    monthObj.weeks.forEach((week: any) => {
      const weekStartDate = new Date(week.week_start_date);
      const weekEndDate = new Date(week.week_end_date);
      
      if (weekEndDate <= today || (weekStartDate <= today && weekEndDate >= today)) {
        const weekRange = `${week.week_start_date.split('T')[0]} to ${week.week_end_date.split('T')[0]}`;

        const weekData = data[weekRange];

        if (weekData) {
          result.push({
            name: week.number ?? week.week_start_date,
            monotony: Math.round(weekData.monotony * 100) / 100,
            WTL: Math.round(weekData.weekly_load * 100) / 100,
            strain: Math.round(weekData.strain * 100) / 100,
            all_trainings_minutes: weekData?.all_trainings_minutes ?? 0,
          });
        } else {
          result.push({
            name: week.number ?? week.week_start_date,
            monotony: 0,
            WTL: 0,
            strain: 0,
            all_trainings_minutes: 0,
          });
        }
      }
    });
  });

  return result;
};


export const getSeasonMonthsAndWeeks = (startDate: any, endDate: any) => {
  const months = [];
  let currentDate = new Date(getMondayDate(startDate));
  let endSeasonDate = new Date(getSundayDate(endDate));
  let mc = 0;

  while (currentDate <= endSeasonDate) {
    const monthName = currentDate.toLocaleString("en-US", { month: "short" });

    const weeks = [];

    let nextMonth: any = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );

    if (
      endSeasonDate.getFullYear() === nextMonth.getFullYear() &&
      endSeasonDate.getMonth() === nextMonth.getMonth()
    ) {
      nextMonth = endSeasonDate;
    }

    const lastDateOfMonth = new Date(nextMonth - 1);

    const startOfWeek = new Date(currentDate);
    let endOfWeek = new Date(currentDate);

    while (endOfWeek <= lastDateOfMonth && endOfWeek <= endSeasonDate) {
      if (startOfWeek.getDay() === 1) {
        const weekStartDate = new Date(startOfWeek);
        const weekEndDate = new Date(endOfWeek);
        weekEndDate.setDate(weekEndDate.getDate() + 6);
        weekEndDate.setHours(23, 59, 0, 0);

        weekStartDate.setDate(weekStartDate.getDate() + 1);
        weekStartDate.setHours(0, 0, 0, 0);

        weeks.push({
          week_start_date: convertDateToCalendarRender(
            weekStartDate.toISOString()
          ),
          week_end_date: convertDateToCalendarRender(
            weekEndDate.toISOString(),
            true
          ),
          number: mc + 1,
        });
        mc++;
      }
      startOfWeek.setDate(startOfWeek.getDate() + 1);
      endOfWeek = new Date(startOfWeek);
    }

    const weeksForCurrentMonth = [];
    const weeksForNextMonth = [];

    // Split weeks into current and next month
    for (const week of weeks) {
      if (
        new Date(week.week_start_date).getMonth() === currentDate.getMonth()
      ) {
        weeksForCurrentMonth.push(week);
      } else  {
        weeksForNextMonth.push(week);
      }
    }
    if (weeks.length > 0) {
      months.push({
        month: monthName,
        weeks: weeksForCurrentMonth,
      });
    }

    if (weeksForNextMonth.length > 0) {
      months.push({
        month: nextMonth.toLocaleString("en-US", { month: "long" }),
        weeks: weeksForNextMonth,
      });
    }

    currentDate = nextMonth;
  }

  return months;
};

//Setts competitions to generated table skeleton
export const setCompetitionsToCalendar = async (
  calendar: any,
  competitions: any
): Promise<any> => {
  return calendar.map(async (month: any) => {
    const weeks = month.weeks.map(async (week: any) => {
      const competition = await competitions.find((com: any) => {
        const competitionDate = new Date(com?.date_of_competition);
        const weekStartDate = new Date(week?.week_start_date);
        const weekEndDate = new Date(week?.week_end_date);
        return (
          competitionDate >= weekStartDate && competitionDate <= weekEndDate
        );
      });
      if (competition) {
        const competitionData = await plansService.getCompetition(
          competition.id
        );
        return { ...week, competition: competitionData.data };
      }
      return week;
    });
    const updatedWeeks = await Promise.all(weeks);
    return {
      ...month,
      weeks: updatedWeeks,
    };
  });
};

function isDateBetweenInMonth(
  whichDate: any,
  start_date_str: any,
  end_date_str: any
) {
  const current_date = moment(whichDate);

  const start_date = moment(start_date_str, "YYYY-MM-DD");
  const end_date = moment(end_date_str, "YYYY-MM-DD");

  return current_date.isBetween(start_date, end_date, null, "[]");
}

function isDateBetween(start_date_str: any, end_date_str: any) {
  const current_date = moment();

  const start_date = moment(start_date_str, "YYYY-MM-DD");
  const end_date = moment(end_date_str, "YYYY-MM-DD");

  return current_date.isBetween(start_date, end_date, null, "[]");
}

export function isDateBetweenUtc(start_date_str: any, end_date_str: any) {
  const current_date = moment.utc();
  const start_date = moment.utc(start_date_str, "YYYY-MM-DD");
  const end_date = moment.utc(end_date_str, "YYYY-MM-DD").endOf("day");
  return current_date.isBetween(start_date, end_date, null, "[]");
}

// transform annual planns to be for evry athlete only newest seasson
export const athleteNewestAnnualPlans = (values: any) => {
  const athletesMap = new Map();

  const newVal = values.filter((annual: any) =>
    isDateBetween(annual?.season?.start_date, annual?.season?.end_date)
  );

  // Group the plans by athlete ID and find the newest season for each athlete
  newVal.forEach((plan: any) => {
    const athleteId = plan.athlete.id;
    if (!athletesMap.has(athleteId)) {
      athletesMap.set(athleteId, plan);
    } else {
      const existingPlan = athletesMap.get(athleteId);
      if (plan.season.start_date > existingPlan.season.start_date) {
        athletesMap.set(athleteId, plan);
      }
    }
  });

  // Convert the Map values (unique athlete and newest season) to an array
  const uniqueAthletesWithNewestSeasons = Array.from(athletesMap.values());

  return uniqueAthletesWithNewestSeasons;
};

// export const mapAnnualPlansToItems = (values: any) => {
//   return values?.map((plan: any) => {
//     return {
//       ...plan?.athlete,
//       annualPlanId: plan.id,
//       start_date: plan.season.start_date,
//       end_date: plan.season.end_date,
//     };
//   });
// };

function getMondayDate(dateString: string) {
  const parts = dateString?.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const day = parseInt(parts[2]);

  const date = new Date(year, month, day);
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) + 1; // Adjust for Monday

  const mondayDate = new Date(date);
  mondayDate.setDate(diff);

  const formattedMondayDate = mondayDate.toISOString().split("T")[0];

  return formattedMondayDate;
}

function getSundayDate(dateString: string) {
  const parts = dateString.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1; // Months are zero-based (0 = January)
  const day = parseInt(parts[2]);

  const date = new Date(year, month, day);
  const dayOfWeek = date.getDay();

  // Calculate the difference to the next Sunday
  const diff = dayOfWeek === 0 ? 1 : 7 - dayOfWeek + 1;

  const sundayDate = new Date(date);
  sundayDate.setDate(date.getDate() + diff);

  // Format the result as "YYYY-MM-DD"
  const formattedSundayDate = sundayDate.toISOString().split("T")[0];

  return formattedSundayDate;
}

// export const getSeasonMonthsAndWeeks2 = (startDate: any, endDate: any) => {
//   const months = [];
//   let currentDate = new Date(getMondayDate(startDate));
//   let newEndDate = new Date(getSundayDate(endDate))

//   let mc = 0;

//   while (currentDate <= newEndDate) {
//     const monthName = currentDate.toLocaleString("en-US", { month: "long" });

//     const weeks = [];

//     const nextMonth: any = new Date(
//       currentDate.getFullYear(),
//       currentDate.getMonth() + 1,
//       1
//     );
//     const lastDateOfMonth = new Date(nextMonth - 1);

//     const startOfWeek = new Date(currentDate);
//     let endOfWeek = new Date(currentDate);

//     while (endOfWeek <= lastDateOfMonth) {
//       if (startOfWeek.getDay() === 1) {
//         const weekStartDate = new Date(startOfWeek);
//         const weekEndDate = new Date(endOfWeek);
//         weekEndDate.setDate(weekEndDate.getDate() + 6);
//         weekEndDate.setHours(23, 59, 0, 0);

//         weekStartDate.setDate(weekStartDate.getDate() + 1);
//         weekStartDate.setHours(0, 0, 0, 0);

//         weeks.push({
//           week_start_date: convertDateToCalendarRender(
//             weekStartDate.toISOString()
//           ),
//           week_end_date: convertDateToCalendarRender(
//             weekEndDate.toISOString(),
//             true
//           ),
//           number: mc + 1,
//         });

//         mc++;
//       }

//       startOfWeek.setDate(startOfWeek.getDate() + 1);
//       endOfWeek = new Date(startOfWeek);
//     }

//     months.push({
//       month: monthName,
//       weeks: [...weeks],
//     });

//     currentDate = nextMonth;
//   }

//   return months;
// };

export const sortAnnualPlans = (values: any) => {
  return values
    ?.map((plan: any) => {
      return plan;
    })
    .sort((a: any, b: any) => {
      const isCurrentSeasonA = isDateBetween(
        a?.season?.start_date,
        a?.season?.end_date
      );
      const isCurrentSeasonB = isDateBetween(
        b?.season?.start_date,
        b?.season?.end_date
      );

      if (isCurrentSeasonA && !isCurrentSeasonB) {
        return -1; // a should come first if it's the current season
      } else if (!isCurrentSeasonA && isCurrentSeasonB) {
        return 1; // b should come first if it's the current season
      } else {
        // If both are current season or both are not current season, sort by start_date in descending order
        return moment(b.start_date, "YYYY-MM-DD").diff(
          moment(a.start_date, "YYYY-MM-DD")
        );
      }
    });
};

export const mapAnnualPlansToItems = (values: any) => {
  return values
    ?.map((plan: any) => {
      return {
        ...plan?.athlete,
        annualPlanId: plan.id,
        start_date: plan.season.start_date,
        end_date: plan.season.end_date,
      };
    })
    .sort((a: any, b: any) => {
      const isCurrentSeasonA = isDateBetween(a.start_date, a.end_date);
      const isCurrentSeasonB = isDateBetween(b.start_date, b.end_date);

      if (isCurrentSeasonA && !isCurrentSeasonB) {
        return -1; // a should come first if it's the current season
      } else if (!isCurrentSeasonA && isCurrentSeasonB) {
        return 1; // b should come first if it's the current season
      } else {
        // If both are current season or both are not current season, sort by start_date in descending order
        return moment(b.start_date, "YYYY-MM-DD").diff(
          moment(a.start_date, "YYYY-MM-DD")
        );
      }
    });
};

export function getStartAndEndDateOfCurrentWeek() {
  const now = new Date();
  const sabirac = now.getDay() === 0 ? -6 : 1;
  const firstDayOfWeek = now.getDate() - now.getDay() + sabirac; // Adjust to the first day of the current week (Sunday = 0, Monday = 1, etc.)

  // Calculate the first day of the current week
  const firstDayOfLastWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    firstDayOfWeek
  );

  // Calculate the last day of the previous week by subtracting 1 day from the first day of the current week
  const lastDayOfLastWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    firstDayOfWeek + 6
  );

  return { startDate: firstDayOfLastWeek, endDate: lastDayOfLastWeek };
}

export function getStartAndEndDateOfPreviousWeek() {
  const now = new Date();
  const firstDayOfWeek = now.getDate() - now.getDay() + 1; // Adjust to the first day of the current week (Sunday = 0, Monday = 1, etc.)

  // Calculate the first day of the previous week
  const firstDayOfPreviousWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    firstDayOfWeek - 7
  );

  // Calculate the last day of the previous week by subtracting 1 day from the first day of the previous week
  const lastDayOfPreviousWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    firstDayOfWeek - 1
  );

  return { startDate: firstDayOfPreviousWeek, endDate: lastDayOfPreviousWeek };
}

export const getMicrocycleNumber = (calendarData: any[], weekStartDate: string): number | null => {
  for (const month of calendarData) {
    for (const week of month.weeks) {
      if (week.week_start_date.includes(weekStartDate)) {
        return week.number; 
      }
    }
  }
  return null; 
};

export function countTotalWeeks(data: any) {
  return data.reduce((total: any, month: any) => total + month.weeks.length, 0);
}

export function getNextMonday(): Date {
  const today = new Date();
  const day = today.getDay();
  const daysUntilNextMonday = (8 - day) % 7 || 7;
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);
  return nextMonday;
}

export function getDefaultPhaseEndDate(): Date {
  const nextMonday = getNextMonday(); 
  const secondSunday = new Date(nextMonday);

  secondSunday.setDate(nextMonday.getDate() + 13);

  return secondSunday;
}

export function getDefaultSubPhaseEndDate(startDate: Date): Date {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 13);
  return endDate;
}

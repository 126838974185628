import React from "react";
import { Typography } from "@mui/material";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import DefaultPalette from "../../theme/palette";
import { useLanguageContext } from "../../context/LanguageContext";

const RADIAN = Math.PI / 180;

interface Props {
  data: {
    PHYSICAL: number;
    RECOVERY: number;
    TACTICAL: number;
    TECHNICAL: number;
  };
  COLORS: {name: string, color: string}[];
  width: any;
  height: any;
}

const PieGraph = ({ data, COLORS, width, height }: Props) => {
  const { i18n } = useLanguageContext();
  const palette = DefaultPalette("dark", "custom");

  const chartData = Object.entries(data).map(([key, value]) => ({
    name: key,
    value: Math.round(value * 100) / 100,
  }));

  const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);

  const newData = chartData.filter((item) => item.value !== 0);

  return (
    <React.Fragment>
      {totalValue === 0 ? (
        <Typography
          sx={{
            color: palette.common.white,
            textAlign: "center",
            fontSize: "1.1rem",
            fontWeight: 500,
            marginTop: "20%",
          }}
        >
          {i18n.graphs.no_data}
        </Typography>
      ) : (
        <div style={{marginLeft: "-20px"}}>
        <PieChart width={width} height={height}>
          <Pie
            data={newData}
            cx="50%"
            cy="50%"
            outerRadius={"90%"}
            fill="#8884d8"
            label={({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
              const radius = innerRadius + (outerRadius - innerRadius) / 2;
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
          
              return (
                <text
                  x={x}
                  y={y}
                  fill="white"
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{ fontSize: "15px", fontWeight: "400" }}
                >
                  {`${value}%`}
                </text>
              );
            }}
            labelLine={false}
            dataKey="value"
            stroke="none"
          >
            {chartData?.map((entry: any, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS.find((color) => color.name === entry.name)?.color}
              />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: palette.custom.white,
              borderRadius: "4px",
              border: "none",
            }}
            formatter={(value: number, name: string) => [`${value}%`, `${name}`]}
          />
        </PieChart>
        </div>
      )}
    </React.Fragment>
  );
};

export default PieGraph;

import React, { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainContext";
import { Role } from "../../utils/enums";
import AthleteListItem from "./AthleteListItem";
import { Typography } from "@mui/material";
import DefaultPalette from "../../theme/palette";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import {
  getAnnualPlan,
  getAnnualPlanStats,
  selectPlan,
} from "../../reducers/plansSlice";
import {
  getSeasonMonthsAndWeeks,
  setCompetitionsToCalendar,
} from "../../resources/simpleFunction";

const AthleteList = (props: any) => {
  const { data, setWeeks, isAthlete } = props;
  const { currentProfile } = useContext(MainContext);
  const palette = DefaultPalette("dark", "custom");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const today = new Date();

  const index = data.findIndex((item: any) => {
    const startDate = new Date(item.start_date);
    const endDate = new Date(item.end_date);

    return today >= startDate && today <= endDate;
  });

  const currentSeason = data[index] ?? data[data.length - 1];

  const seasons = isAthlete ? [currentSeason] : data;

  const plans = useAppSelector(selectPlan);

  const onArrowClick = async (id: any) => {
    await dispatch(getAnnualPlan(id));
    navigate("/annualPlan");
  };

  const onCardClick = async (id: any) => {
    let athletePlans: any = await dispatch(getAnnualPlan(id));
    if (athletePlans.type.includes("fulfilled")) {
      if (
        athletePlans?.payload?.season?.start_date &&
        athletePlans?.payload?.season?.end_date
      ) {
        const calendarResult = getSeasonMonthsAndWeeks(
          athletePlans?.payload?.season?.start_date,
          athletePlans?.payload?.season?.end_date
        );

        setWeeks(calendarResult);
      }
    }
    await dispatch(getAnnualPlanStats(id));
  };

  
  useEffect(() => {
    if (plans?.value?.id) {
      onCardClick(plans?.value?.id);
    }
  }, []);

  return (
    <div
      className="container"
      style={{
        display: "flex",
        ...(isAthlete
          ? { borderBottom: `1px solid ${palette.custom.gray}`, width: "100%" }
          : { gap: "5px", flexDirection: "column", }),
      }}
    >
      {!isAthlete && (
        <Typography color={palette.custom.white}>
          {currentProfile?.role !== Role.Athlete ? "Athletes" : "My seasons"}
        </Typography>
      )}
      {seasons &&
        seasons.length > 0 &&
        seasons?.map((item: any) => (
          <AthleteListItem
            key={item.annualPlanId}
            title={item.name}
            subtitle={item.sport}
            subtitle2={`${item.start_date} - ${item.end_date}`}
            isSelected={plans?.valueStats?.id === item.annualPlanId}
            isAthlete={isAthlete}
            onArrowClick={() => onArrowClick(item.annualPlanId)}
            onCardClick={() => onCardClick(item.annualPlanId)}
          />
        ))}
    </div>
  );
};

export default AthleteList;

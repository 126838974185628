import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import WeekStatsItem from "./WeekStatsItem";
import { getAnnualStatsByWeek } from "../../resources/simpleFunction";
import DefaultPalette from "../../theme/palette";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { useLanguageContext } from "../../context/LanguageContext";

interface Props {
  weeks: any[];
  weekStats: any;
}

const WeekStatsList = ({ weeks, weekStats }: Props) => {
  const palette = DefaultPalette("dark", "custom");
  const { i18n } = useLanguageContext();
  const stats = getAnnualStatsByWeek(weekStats, weeks) ?? [];

  const [choosenWeek, setChoosenWeek] = useState(
    stats[stats.length - 1]?.name ?? 1
  );

  const handleWeekChange = (direction: any) => {
    setChoosenWeek((prevWeek: any) => prevWeek + direction);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      {Object.keys(weekStats).length > 0 ? (
        <React.Fragment>
          <IconButton
            onClick={() => handleWeekChange(+1)}
            sx={{ color: palette.common.white }}
            disabled={choosenWeek >= stats.length}
          >
            <KeyboardArrowUpOutlined />
          </IconButton>

          <WeekStatsItem
            week={choosenWeek}
            weeklyLoad={stats[choosenWeek - 1]?.WTL}
            strain={stats[choosenWeek - 1]?.strain}
            monotony={stats[choosenWeek - 1]?.monotony}
            all_trainings_minutes={stats[choosenWeek - 1]?.all_trainings_minutes}
          />
          <IconButton
            onClick={() => handleWeekChange(-1)}
            sx={{ color: palette.common.white }}
            disabled={choosenWeek <= 1}
          >
            <KeyboardArrowDownOutlined />
          </IconButton>
        </React.Fragment>
      ) : (
        <Typography
          variant="h4"
          sx={{
            color: palette.common.white,
            textAlign: "center",
            marginTop: "5%",
          }}
        >
          {i18n.graphs.no_data}
        </Typography>
      )}
    </Box>
  );
};

export default WeekStatsList;

import React from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area,
  Bar,
  Line,
} from "recharts";
import { getAnnualStatsByWeek } from "../../resources/simpleFunction";
import DefaultPalette from '../../theme/palette';

interface Props {
  weeks: any[];
  weekStats: any;
  width: any;
  height: any;
}

const CombineGraphAnnual = ({ weeks, weekStats, width, height }: Props) => {
  const stats = getAnnualStatsByWeek(weekStats, weeks) ?? [];
  const palette = DefaultPalette("dark", "custom");


  return (
    <ComposedChart
      data={stats}
      margin={{ top: 5, right: 0, bottom: 5, left: 30 }}
      width={width - 20}
      height={height - 20}
    >
      <XAxis dataKey="name" />
      {/* Left Y-axis for strain and WTL */}
      <YAxis tickCount={6} domain={[0, "dataMax + 1000"]} yAxisId="big" />
      {/* Right Y-axis for monotony */}
      <YAxis
        yAxisId="small"
        orientation="right"
        tickCount={6}
        domain={[0, "dataMax"]}
      />
      <Tooltip />
      <Legend
        verticalAlign="top"
        align="left"
        iconSize={28}
        wrapperStyle={{
          marginLeft: 75,
          paddingBottom: 5,
          width: "fit-content",
        }}
      />
      <CartesianGrid stroke="#f5f5f523" strokeDasharray="0 0" />
      {/* Area for strain (left Y-axis) */}
      <Area
        yAxisId={"big"}
        type="linear"
        dataKey="strain"
        fill={palette.custom.graph.grey}
        stroke={palette.custom.graph.grey}
        legendType="rect"
      />
      {/* Bar for WTL (left Y-axis) */}
      <Bar
        yAxisId={"big"}
        dataKey="WTL"
        barSize={60}
        fill={ palette.custom.graph.blue}
        radius={[5, 5, 0, 0]}
      />
      {/* Line for monotony (right Y-axis) */}
      <Line
        yAxisId={"small"} // Use the right Y-axis
        type="linear"
        dataKey="monotony"
        stroke={palette.custom.graph.orange}
        strokeWidth={2}
      />
    </ComposedChart>
  );
};

export default CombineGraphAnnual;
